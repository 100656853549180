@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
    url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
    url('../fonts/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Semibold'), local('Gilroy-SemiBold'),
    url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Italic'), local('Gilroy-RegularItalic'),
    url('../fonts/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}